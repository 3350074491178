<template>
<div>
  <div class="container-fluid">
    <PageTitle />
  
    <div 
      v-if="isList" 
      class="card"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <h5 class="card-title">{{pageTitle}} List</h5>
          </div>
          <div class="col-md-9">
            <div class="row justify-content-end">
              <div class="col-md-5 ml-auto mb-2">
                <form @submit.prevent="doFilter()">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input 
                        v-model="filter.search"
                        type="text" 
                        class="form-control" 
                        placeholder="Search"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12 col-sm-auto col-md-auto">
                <button class="btn btn-info btn-block" type="button" @click="doReset">Reset</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
          <table class="table product-overview table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Full Name</th>
                <th>Level</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
              <tr v-for="(v,k) in data.data" :key="k">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td>{{ v.bu_full_name }}</td>
                <td>{{ v.bul_name }}</td>
                <td><LabelStatus :status="v[statusKey]" /></td>
                <td>
                  <router-link 
                    v-if="moduleRole('Edit')"
                    :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                    class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                    <i class="ti-marker-alt"></i>
                  </router-link> 
                  <a 
                    v-if="v.bu_id !== user.id && moduleRole('Edit')"
                    href="javascript:;" 
                    class="text-inverse icon_action act_icon mx-1" v-tooltip="'Change Status'"
                    @click="doChangeStatus(k,v)">
                    <i class="ti-settings"></i>
                  </a> 
                  <a 
                    v-if="v.bu_id !== user.id && moduleRole('Edit')"
                    href="javascript:;" 
                    class="text-inverse icon_action act_icon" 
                    v-tooltip="'Delete'"
                    @click="doDelete(k,v)">
                    <i class="ti-trash"></i>
                  </a>
                </td>
              </tr>
              <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
            </tbody>
          </table>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="pagination-flat float-right" v-if="data.data!==false&&data.data.length">
              <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                <span slot="prev-nav">Previous</span>
                <span slot="next-nav">Next</span>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(submitUserForm)">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Full Name 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_full_name"
                        type="text" 
                        class="form-control" 
                        placeholder="e.g. John" 
                        tabindex="1"
                      /> 
                      <VValidate name="Full Name" v-model="row.bu_full_name" :rules="mrValidation.bu_full_name" />
                    </div>
                    <div class="form-group">
                      <label class="control-label">
                        Mobile Number 
                      </label>
                      <input 
                        v-model="row.bu_phone"
                        type="text" 
                        class="form-control" 
                        placeholder="e.g. joinemail@example.com" 
                        tabindex="3"
                      /> 
                      <VValidate name="Mobile Number" v-model="row.bu_phone" :rules="{regex:/^[0-9\+]+$/ }" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Email Address 
                      </label>
                      <input 
                        v-model="row.bu_email_address"
                        type="email" 
                        class="form-control" 
                        placeholder="e.g. joinemail@example.com" 
                        tabindex="2"
                      /> 
                      <VValidate name="Email Address" v-model="row.bu_email_address" :rules="mrValidation.bu_email_address" />
                    </div>
                    <div class="form-group">
                      <label class="control-label">
                        Username 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_username"
                        type="text" 
                        class="form-control" 
                        placeholder="e.g. john" 
                        tabindex="4"
                        autocomplete="username"
                      /> 
                      <VValidate name="Username" v-model="row.bu_username" :rules="mrValidation.bu_username" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Password 
                        <span v-if="!row.bu_id" class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_password"
                        type="password" 
                        class="form-control" 
                        tabindex="5"
                        autocomplete="new-password"
                      /> 
                      <VValidate name="Password" v-model="row.bu_password" :rules="{required:!row.bu_id, min:5}" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Confirm Password 
                        <span v-if="!row.bu_id" class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.bu_password2"
                        type="password" 
                        class="form-control" 
                        tabindex="6"
                        autocomplete="new-password"
                      /> 
                      <VValidate name="Confirm Password" v-model="row.bu_password2" rules="confirmed:Password" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Level Name 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <VSelect
                        v-model="row.bu_bul_id" 
                        placeholder="Select Level Name"
                        :options="mrLevel" 
                        :reduce="v=>v.bul_id" 
                        label="bul_name"
                        :tabindex="7"
                      />
                      <VValidate name="Level Name" v-model="row.bu_bul_id" :rules="mrValidation.bu_bul_id" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Status 
                        <span class="text-danger mr5">*</span> 
                      </label>
                      <div>
                        <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                        <InputRadio v-model="row[statusKey]" name="status" option="N" />
                      </div>
                      <VValidate name="Status" v-model="row[statusKey]" :rules="mrValidation[statusKey]" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">
                    Foto Profil 
                  </label>
                  <Uploader v-model="row.bu_avatar" type="user" label="Foto"/>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12 text-right">
                <router-link :to="{ name: $route.name }" class="btn btn-rounded btn-light mr-2">Cancel</router-link>
                <button type="submit" class="btn btn-rounded btn-info">{{isAdd ? "Submit" : "Update"}}</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import { mapActions } from 'vuex'
export default{
  extends:GlobalVue,
  components:{PageTitle},
  data(){
    return {
      idKey:'bu_id',
      statusKey:'bu_is_active',
      mrLevel:[],
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    ...mapActions(['watchAuth']),
    submitUserForm() {
      this.doSubmitCRUD()
      if (!this.isAdd) {
        setTimeout(() => {
          this.watchAuth()
        }, 1000)
      }
    }
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>